// chatConstants.js
export const GET_CHAT_ROOMS_REQUEST = 'GET_CHAT_ROOMS_REQUEST';
export const GET_CHAT_ROOMS_SUCCESS = 'GET_CHAT_ROOMS_SUCCESS';
export const GET_CHAT_ROOMS_FAIL = 'GET_CHAT_ROOMS_FAIL';

export const GET_CHAT_MESSAGES_REQUEST = 'GET_CHAT_MESSAGES_REQUEST';
export const GET_CHAT_MESSAGES_SUCCESS = 'GET_CHAT_MESSAGES_SUCCESS';
export const GET_CHAT_MESSAGES_FAIL = 'GET_CHAT_MESSAGES_FAIL';

export const SEND_CHAT_MESSAGE_REQUEST = 'SEND_CHAT_MESSAGE_REQUEST';
export const SEND_CHAT_MESSAGE_SUCCESS = 'SEND_CHAT_MESSAGE_SUCCESS';
export const SEND_CHAT_MESSAGE_FAIL = 'SEND_CHAT_MESSAGE_FAIL';


export const CHAT_CONNECT_SUCCESS = 'CHAT_CONNECT_SUCCESS';
export const CHAT_CONNECT_FAIL = 'CHAT_CONNECT_FAIL';
export const CHAT_MESSAGE_RECEIVED = 'CHAT_MESSAGE_RECEIVED';
export const CHAT_SEND_MESSAGE_FAIL = 'CHAT_SEND_MESSAGE_FAIL';
export const CHAT_DISCONNECTED = 'CHAT_DISCONNECTED';
