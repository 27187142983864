export const PAYMENT_CREATE_REQUEST = 'PAYMENT_CREATE_REQUEST';
export const PAYMENT_CREATE_SUCCESS = 'PAYMENT_CREATE_SUCCESS';
export const PAYMENT_CREATE_FAIL = 'PAYMENT_CREATE_FAIL';

export const PAYMENT_LIST_REQUEST = 'PAYMENT_LIST_REQUEST';
export const PAYMENT_LIST_SUCCESS = 'PAYMENT_LIST_SUCCESS';
export const PAYMENT_LIST_FAIL = 'PAYMENT_LIST_FAIL';

export const ADMIN_PAYMENT_LIST_REQUEST = 'ADMIN_PAYMENT_LIST_REQUEST';
export const ADMIN_PAYMENT_LIST_SUCCESS = 'ADMIN_PAYMENT_LIST_SUCCESS';
export const ADMIN_PAYMENT_LIST_FAIL = 'ADMIN_PAYMENT_LIST_FAIL';

export const LIST_ALL_PAYMENTS_REQUEST = 'LIST_ALL_PAYMENTS_REQUEST';
export const LIST_ALL_PAYMENTS_SUCCESS = 'LIST_ALL_PAYMENTS_SUCCESS';
export const LIST_ALL_PAYMENTS_FAIL = 'LIST_ALL_PAYMENTS_FAIL';

export const PAYSOFTER_PAYMENT_CREATE_REQUEST = 'PAYSOFTER_PAYMENT_CREATE_REQUEST';
export const PAYSOFTER_PAYMENT_CREATE_SUCCESS = 'PAYSOFTER_PAYMENT_CREATE_SUCCESS';
export const PAYSOFTER_PAYMENT_CREATE_FAIL = 'PAYSOFTER_PAYMENT_CREATE_FAIL';

export const DEBIT_PAYSOFTER_ACCOUNT_REQUEST = 'DEBIT_PAYSOFTER_ACCOUNT_REQUEST';
export const DEBIT_PAYSOFTER_ACCOUNT_SUCCESS = 'DEBIT_PAYSOFTER_ACCOUNT_SUCCESS';
export const DEBIT_PAYSOFTER_ACCOUNT_FAIL = 'DEBIT_PAYSOFTER_ACCOUNT_FAIL';

export const CREATE_PAYSOFTER_PROMISE_REQUEST = 'CREATE_PAYSOFTER_PROMISE_REQUEST';
export const CREATE_PAYSOFTER_PROMISE_SUCCESS = 'CREATE_PAYSOFTER_FUND_PROMISE_SUCCESS';
export const CREATE_PAYSOFTER_PROMISE_FAIL = 'CREATE_PAYSOFTER_FUND_PROMISE_FAIL';

export const GET_PAYMENT_API_KEYS_REQUEST = 'GET_PAYMENT_API_KEYS_REQUEST';
export const GET_PAYMENT_API_KEYS_SUCCESS = 'GET_PAYMENT_API_KEYS_SUCCESS';
export const GET_PAYMENT_API_KEYS_FAIL = 'GET_PAYMENT_API_KEYS_FAIL';

export const DEBIT_PAYSOFTER_USD_ACCOUNT_REQUEST = 'DEBIT_PAYSOFTER_USD_ACCOUNT_REQUEST';
export const DEBIT_PAYSOFTER_USD_ACCOUNT_SUCCESS = 'DEBIT_PAYSOFTER_USD_ACCOUNT_SUCCESS';
export const DEBIT_PAYSOFTER_USD_ACCOUNT_FAIL = 'DEBIT_PAYSOFTER_USD_ACCOUNT_FAIL';
