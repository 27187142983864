export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAIL = "SEND_OTP_FAIL";

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL";

export const VERIFY_USD_OTP_REQUEST = "VERIFY_USD_OTP_REQUEST"; 
export const VERIFY_USD_OTP_SUCCESS = "VERIFY_USD_OTP_SUCCESS";
export const VERIFY_USD_OTP_FAIL = "VERIFY_USD_OTP_FAIL";
