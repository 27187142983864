// PaymentScreen.js
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Modal, ListGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../config/apiConfig";
import { clearCart } from "../../actions/cartActions";
import { createPayment } from "../../actions/paymentActions";
import Message from "../Message";
import Loader from "../Loader";
import ApplyPromoCode from "../ApplyPromoCode";
import Paystack from "../payment/Paystack";
// import {Paysofter} from "../react-paysofter/Paysofter";
import { Paysofter } from "react-paysofter";

function PaymentScreen() {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      window.location.href = "/login";
    }
  }, [userInfo]);

  const [paysofterPublicKey, setPaysofterPublicKey] = useState("");
  const [paystackPublicKey, setPaystackPublicKey] = useState("");
  const [reference, setReference] = useState("");
  const userEmail = userInfo.email;

  const location = useLocation();
  const { pathname } = location;
  const order_id = pathname.split("/payment/")[1];

  const currency = "NGN";
  const createdAt = new Date().toISOString();

  const [paymentInitiated, setPaymentInitiated] = useState(false);

  const paymentCreate = useSelector((state) => state.paymentCreate);
  const { loading, success, error } = paymentCreate;

  const applyPomoCodeState = useSelector((state) => state.applyPomoCodeState);
  const { promoDiscount, discountPercentage } = applyPomoCodeState;
  console.log(
    "Paystack promoDiscount:",
    promoDiscount,
    "discountPercentage:",
    discountPercentage
  );
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState(null);

  // const shipmentSave = useSelector((state) => state.shipmentSave);
  // console.log("shipmentSave:", shipmentSave);

  const shipmentSave = JSON.parse(localStorage.getItem("shipmentData")) || [];
  console.log("shipmentSave:", shipmentSave);

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const [showInfoModal, setShowInfoModal] = useState(false);

  const handleInfoModalShow = () => {
    setShowInfoModal(true);
  };

  const handleInfoModalClose = () => {
    setShowInfoModal(false);
  };

  // useEffect(() => {
  //   if (success) {
  //     // history.push("/");
  //   }
  // }, [success, history]);

  const itemsPrice = cartItems.reduce(
    (acc, item) => acc + item.qty * item.price,
    0
  );

  const shippingPrice = cartItems.length > 0 ? 1000 : 0;
  const taxPrice = cartItems.reduce(
    (acc, item) => acc + item.qty * item.price * 0.1,
    0
  );

  const totalPrice = itemsPrice + shippingPrice + taxPrice;

  const promoTotalPrice = totalPrice - promoDiscount;
  console.log(
    "totalPrice:",
    totalPrice,
    "promoDiscount:",
    promoDiscount,
    "promoTotalPrice:",
    promoTotalPrice
  );

  const finalItemsPrice = itemsPrice - promoDiscount;
  console.log("finalItemsPrice:", finalItemsPrice);

  useEffect(() => {
    const getPaymentDetails = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/get-payment-details/`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.access}`,
            },
          }
        );
        setPaysofterPublicKey(response.data.paysofterPublicKey);
        setPaystackPublicKey(response.data.paystackPublicKey);
        setReference(response.data.reference);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchData = async () => {
      await getPaymentDetails();
    };
    fetchData();
  }, [userInfo.access]);

  const handlePaymentGatewaySelection = (paymentGateway) => {
    setSelectedPaymentGateway(paymentGateway);
  };

  const paymentData = {
    reference,
    order_id,
    totalPrice,
    taxPrice,
    userEmail,
    shippingPrice,
    itemsPrice,
    finalItemsPrice,
    promoDiscount,
    discountPercentage,
    promoTotalPrice,
    paystackPublicKey,
    paysofterPublicKey,
    shipmentSave,
    currency,
  };
  console.log("paymentData:", paymentData);

  const handleOnSuccess = () => {
    console.log("handling onSuccess...");
    const paymentDetails = {
      reference: reference,
      order_id: order_id,
      amount: totalPrice,
      email: userEmail,

      items_amount: itemsPrice,
      final_items_amount: finalItemsPrice,
      promo_code_discount_amount: promoDiscount,
      promo_code_discount_percentage: discountPercentage,
      final_total_amount: promoTotalPrice,
    };
    dispatch(createPayment(paymentDetails));
  };

  const onSuccess = () => {
    handleOnSuccess();
  };

  const handleOnClose = () => {
    console.log("handling onClose...");
    window.location.reload();
    // window.location.href = "/";
  };

  const onClose = () => {
    handleOnClose();
  };

  useEffect(() => {
    if (success) {
      dispatch(clearCart());
      const timer = setTimeout(() => {
        window.location.reload();
        window.location.href = "/";
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [dispatch, success]);

  const initiatePayment = () => {
    setPaymentInitiated(true);
  };

  return (
    <>
      <Row>
        <div className="d-flex justify-content-center">
          <Col md={6}>
            <h1 className="text-center py-2">Payment Page</h1>
            {error && <Message variant="danger">{error}</Message>}
            {loading && <Loader />}

            <div className="text-center py-2">
              <Row className="text-center py-2">
                <Col md={11}>
                  <Button
                    variant="dark"
                    onClick={() => handlePaymentGatewaySelection("paystack")}
                    className="mr-2 rounded w-100"
                  >
                    Pay with Paystack
                  </Button>
                </Col>
                <Col md={1}>
                  <Button variant="outline">
                    {/* <i className="fa fa-info-circle"></i> */}
                  </Button>
                </Col>
              </Row>

              <Row className="text-center py-2">
                <Col md={11}>
                  <Button
                    variant="primary"
                    onClick={() => handlePaymentGatewaySelection("paysofter")}
                    className="mr-2 rounded w-100"
                    // disabled
                  >
                    Pay with Paysofter
                  </Button>
                </Col>
                <Col md={1}>
                  <Button
                    variant="outline"
                    onClick={handleInfoModalShow}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Don't have a Paysofter account? Click here."
                  >
                    <i className="fa fa-info-circle"> </i>
                  </Button>

                  <Modal show={showInfoModal} onHide={handleInfoModalClose}>
                    <Modal.Header closeButton>
                      <Modal.Title className="text-center w-100 py-2">
                        Paysofter Account
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p className="text-center">
                        Don't have a Paysofter account? You're just about 3
                        minutes away! Sign up for a much softer payment
                        experience.{" "}
                        <a
                          href="https://paysofter.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          <span>
                            <Button
                              variant="primary"
                              size="sm"
                              className="text-center py-2"
                            >
                              Create A Free Account
                            </Button>
                          </span>
                        </a>
                      </p>
                    </Modal.Body>
                  </Modal>
                </Col>
              </Row>
            </div>

            {selectedPaymentGateway === "paystack" && (
              <Paystack paymentData={paymentData} />
            )}

            {selectedPaymentGateway === "paysofter" && (
              <>
                <h2 className="text-center py-3">Paysofter Payment Option</h2>
                <ListGroup variant="flush">
                  {cartItems.map((item) => (
                    <ListGroup.Item key={item.product}>
                      <Row>
                        <Col md={4}>
                          <img
                            src={item.image}
                            alt={item.name}
                            className="img-fluid"
                          />
                        </Col>
                        <Col md={8}>
                          <p>{item.name}</p>
                          <p>
                            {item.qty} x NGN {item.price} = NGN{" "}
                            {item.qty * item.price}
                          </p>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                  <ListGroup.Item>Order ID: {order_id}</ListGroup.Item>
                  <ListGroup.Item>
                    Shipping Address: {shipmentSave.address},{" "}
                    {shipmentSave.city}, {shipmentSave.country}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Shipping Cost: NGN{" "}
                    {shippingPrice.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Tax: NGN{" "}
                    {taxPrice.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Total Amount: NGN{" "}
                    {totalPrice.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </ListGroup.Item>

                  <ListGroup.Item>
                    Promo Discount: NGN{" "}
                    {promoDiscount ? (
                      <span>
                        {promoDiscount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        ({discountPercentage}%)
                      </span>
                    ) : (
                      <span>0</span>
                    )}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Final Total Amount: NGN{" "}
                    {promoTotalPrice ? (
                      <span>
                        {promoTotalPrice.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    ) : (
                      <span>
                        {totalPrice.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    )}
                  </ListGroup.Item>
                  <ListGroup.Item>Timestamp: {createdAt}</ListGroup.Item>
                </ListGroup>

                <div className="text-center py-2">
                  <ApplyPromoCode order_id={order_id} />
                </div>

                <div className="d-flex justify-content-center py-2">
                  <Button
                    className="py-1 rounded"
                    variant="primary"
                    onClick={initiatePayment}
                  >
                    Pay Now
                  </Button>
                </div>

                {paymentInitiated && (
                  <Paysofter
                    email={userEmail}
                    currency={currency}
                    amount={finalItemsPrice}
                    paysofterPublicKey={paysofterPublicKey}
                    onSuccess={onSuccess}
                    onClose={onClose}
                    paymentRef={reference}
                    showPromiseOption={true}
                    showFundOption={true}
                    showCardOption={true}
                  />
                )}
              </>
            )}
          </Col>
        </div>
      </Row>
    </>
  );
}

export default PaymentScreen;
